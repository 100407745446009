* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-transform: capitalize;
}

/* --light-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
--dark-shadow: 0 5px 15px rgba(0, 0, 0, 0.2); */
a {
  text-decoration: none;
  color: inherit;
}
li {
  list-style: none;
}
body {
  background: rgb(193, 191, 191);
  background: linear-gradient(
      45deg,
      rgba(193, 191, 191, 1) 19%,
      rgba(255, 233, 214, 1) 53%,
      rgba(236, 192, 131, 1) 85%
    ),
    no-repeat;
  background-size: cover;
}
.container {
  width: 400px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  transition: all 0.2s linear;
  text-transform: none;
  font-family: "Changa", sans-serif;
  user-select: none;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  line-height: 1;
}
input {
  font-family: "Changa", sans-serif;
}
.container:hover {
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}
.pages-container {
  width: 100%;
  min-height: 780px;
  max-height: 780px;
  margin: 0 auto;
  border-radius: 40px;
  position: relative;
  background-color: #fff;
  overflow: hidden;
}
@media only screen and (max-width: 600px) {
  body {
    background-color: #fff !important;
    background-image: none;
  }
  .container {
    min-width: 100vw;
    min-height: 100vh;
    justify-content: flex-start;
    margin: 0 auto;
  }
  .container:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0) !important;
  }
  .pages-container {
    min-height: 100vh;
    border-radius: unset;
  }
}

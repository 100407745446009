.products-page {
  border-radius: 40px;
  padding: 2.1rem 0rem 2.1rem 1rem;
}
.header-of-products {
  width: 100%;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
.discover-bar a,
.header-of-products a {
  width: 2.1rem;
  height: 2.1rem;
  font-size: 1.1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  color: #000;
  transition: all 0.3s linear;
  position: relative;
}
.discover-bar a span {
  width: 15px;
  height: 15px;
  display: flex;
  bottom: 5px;
  right: 5px;
  justify-content: center;
  position: absolute;
  font-size: 0.65rem;
  background-color: #f2984a;
  color: #fff;
  border-radius: 50%;
}
.header-of-products a:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition: all 0.2s linear;
}
.discover-bar {
  height: 1.2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
.product-title-search {
  width: 100%;
  height: 3.5rem;
  margin: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-between;
}
.product-search-box {
  margin: 20px auto;
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-right: 1rem;
}
.search-box {
  width: 85%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
}
.search-box:hover {
  border: 1px solid #f2994a;
}
.search-box-icon {
  font-size: 1.1rem;
  margin: 8px 5px 0px 10px;
  color: #a1a1a1;
}
.adjustment-icon {
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2994a;
  color: #fff;
  font-size: 1.5rem;
  transform: rotate(90deg);
  cursor: pointer;
  transition: all 0.3s linear;
}
.product-search-box input {
  width: 90%;
  height: 100%;
  font-size: 0.85rem;
  border: none;
  outline: none;
}
.popular-title-bar {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  font-size: 1.2em;
  padding-right: 1rem;
}
.popular-title-bar a {
  font-size: 0.75rem;
  letter-spacing: 1px;
  color: #a1a1a1;
}
.products-container {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-right: -15px;
}
.single-product {
  width: 240px;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 25px;
  background: rgb(200, 217, 217);
  background: linear-gradient(
    135deg,
    rgba(200, 217, 217, 1) 19%,
    rgba(255, 245, 236, 1) 53%,
    rgba(236, 205, 163, 1) 85%
  );
  border-radius: 20px;
  position: relative;
}
.like-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #f2994a;
  cursor: pointer;
  border-radius: 50%;
  top: 15px;
  right: 15px;
  position: absolute;
  transition: all 0.3s linear;
}
.like-icon:hover {
  background-color: #f2994a;
  color: #fff;
  transition: all 0.2s linear;
}
.active {
  background-color: #f2994a;
  color: #fff;
}
.product-description {
  margin: 10px auto;
}
.product-description span,
.product-description a {
  width: 1.5rem;
  height: 1.5rem;
  bottom: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f2994a;
  color: #fff;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  transition: all 0.3s linear;
}
.product-description a:hover,
.discover-bar a:hover,
.product-description span:hover,
.product-description-list-2 span:hover,
.like-icon:hover,
.adjustment-icon:hover {
  transition: all 0.2s linear;
  box-shadow: 0 5px 15px 1px #f2984a85;
}
.product-description h5,
.product-description-list-2 h5 {
  margin: 10px 0;
  color: #646464;
}
.headphone-pic {
  width: 200px;
  height: 200px;
  transform: rotate(20deg);
}
.products-container-list-2 {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  margin-top: 5px;
}
.single-product-list-2 {
  width: 246px;
  height: 150px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-around;
  background: rgb(200, 217, 217);
  background: linear-gradient(
    135deg,
    rgba(200, 217, 217, 1) 19%,
    rgba(255, 245, 236, 1) 53%,
    rgba(236, 205, 163, 1) 85%
  );
  border-radius: 20px;
  position: relative;
  padding: 5px 15px 15px 15px;
}
.headphone-pic-list-2 {
  width: 100px;
  height: 100px;
  transform: rotate(20deg);
  margin-right: 5px;
}
.product-description-list-2 {
  font-size: 0.9rem;
  margin-top: -20px;
}

.product-description-list-2 span {
  width: 1.5rem;
  height: 1.5rem;
  bottom: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #f2994a;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  font-size: 1.2rem;
  background-color: #fff;
}
.single-product:hover,
.single-product-list-2:hover {
  cursor: pointer;
}
@media only screen and (max-width: 600px) {
  .products-page {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.5rem 0.5rem;
    padding-right: 0;
  }
  .single-product-list-2 {
    width: 100%;
  }
}

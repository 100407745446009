.getstarted-page {
  width: 100%;
  min-height: 780px;
  color: #222;
  background: linear-gradient(
      -45deg,
      rgba(214, 227, 227, 0) 0%,
      rgba(232, 235, 235, 0.678) 100%
    ),
    url(../../assets/getStartedPage_bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: center;
  position: relative;
}
.getstarted-title-container {
  padding: 110px 90px 0 15px;
  line-height: 1.85rem;
  word-spacing: 1px;
}
.getstarted-title-container span {
  color: #f2994a;
}
.btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 100px;
}
.gets-btn {
  width: 50%;
  height: 3.3rem;
  margin: 0 auto;
  color: #fff;
  display: flex;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 12px;
  align-items: center;
  justify-content: center;
  background-color: #f2994a;
  transition: all 0.2s linear;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  text-decoration: none;
}
.gets-btn:hover {
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 5px 20px 2px #4af2ea88;
}
@media only screen and (max-width: 600px) {
  .getstarted-page {
    min-height: 100vh;
  }
}

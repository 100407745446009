.item-page {
  border-radius: 40px;
  background-color: #f1f1ef;
}
.header-of-item {
  width: 100%;
  height: 3.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 2.1rem 1rem 0rem 1rem;
}

.header-of-item a {
  width: 2.1rem;
  height: 2.1rem;
  font-size: 1.1rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  color: #000;
  transition: all 0.3s linear;
  position: relative;
}
.item-change-color span:hover,
.header-of-item a:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.2s linear;
}
.cardcunter {
  width: 15px;
  height: 15px;
  display: flex;
  bottom: 5px;
  right: 5px;
  justify-content: center;
  align-items: center;
  position: absolute;
  font-size: 0.65rem;
  background-color: #f2984a;
  color: #fff;
  border-radius: 50%;
  z-index: 10px;
  font-size: 0.5rem;
}
.headphone-pic-item {
  width: 250px;
  height: 250px;
  margin-top: 20px;
  transform: rotate(20deg);
  margin: 10px auto;
}

.item-img-container {
  width: 100%;
  height: auto;
  padding-bottom: 1.8rem;
  color: #000;
  position: relative;
}
.item-single-pic {
  display: flex;
  align-items: center;
  justify-content: center;
}
.item-change-color {
  width: 2.3rem;
  height: 8rem;
  margin-right: 1rem;
  position: absolute;
  top: 70px;
  right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 10;
}
.item-change-color p {
  width: 1.9rem;
  height: 1.9rem;
  border-radius: 50%;
  margin: 2px auto;
  border: 2px solid #fff;
}
.item-change-color span {
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s linear;
}
.item-swiper {
  width: 350px !important;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.color-link1 {
  background-color: rgb(90, 90, 90);
}
.color-link2 {
  background-color: #eccb61;
}
.color-link3 {
  background-color: #7197ad;
}
.add-to-card-part {
  background-color: #fff;
  border-radius: 30px;
  padding: 30px 15px 0px 15px;
  position: relative;
}
.item-title-product {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.item-title-product h3 {
  width: 250px;
  padding-right: 10px;
}
.item-like-icon {
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  color: #f2994a;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s linear;
  font-size: 1.1em;
  border: 1px solid #f2994a;
}
.item-like-icon:hover {
  background-color: #f2994a;
  color: #fff;
  transition: all 0.2s linear;
}
.item-stars-container {
  font-size: 0.8rem;
  margin-bottom: 20px;
}
.item-details {
  height: 20vh;
  height: 4.5rem;
  word-break: keep-all;
}
.item-details h4 {
  margin: 10px 0;
}
.item-details p {
  font-size: 0.7rem;
  max-height: 2.6rem;
  overflow-x: scroll;
}
.item-details {
  margin-bottom: 10px;
}
.item-price-card {
  margin: 15px 0;
  height: 20vh;
}
.item-price-card h4 {
  margin-bottom: 5px;
}
.item-addcard-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.add-card-count {
  width: 4rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.item-addcard-price span {
  width: 1.3rem;
  height: 1.3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.3s linear;
  font-size: 1.1em;
  color: #222;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
}
.item-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 30px;
  left: 0;
  padding: 0 15px;
}
.items-btn {
  width: 100%;
  height: 2.8rem;
  margin: 0 auto;
  color: #fff;
  display: flex;
  border: none;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: #f2994a;
  transition: all 0.2s linear;
  text-decoration: none;
}
.items-btn:hover {
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 5px 20px 2px #4af2ea88;
}
.focused {
  color: #222;
  background-color: #caee98e9;
  border: 1px solid #b2f24a;
}
@media only screen and (max-width: 600px) {
  .item-btn-container {
    width: 95%;
    position: fixed;
    bottom: 5px;
    left: 2.5%;
  }
  .item-price-card {
    width: 95%;
    position: fixed;
    bottom: 60px;
    left: 2.5%;
    z-index: 10;
    background-color: #fff;
  }
}

.favorites-page {
  border-radius: 40px;
  padding: 2.1rem 1rem;
  min-height: 780px;
  min-width: 100%;
}
.header-of-favorites {
  width: 100%;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.header-of-favorites a {
  width: 2.1rem;
  height: 2.1rem;
  font-size: 1.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  color: #000;
  transition: all 0.3s linear;
}
.header-of-favorites a:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.2s linear;
}
.favorites-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: space-around;
}
.favorite-single-product {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  background-color: #f1f1ef;
  border-radius: 20px;
  position: relative;
  padding: 5px 15px 15px 15px;
  transition: all 0.3s linear;
}
.favorite-items-container {
  margin-top: 10px;
}
.favorite-items-container li {
  margin: 10px auto;
}
.favorites-product-description a,
.favorites-product-description span {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  transition: all 0.3s linear;
}
.favorites-product-description a {
  bottom: 15px;
  right: 15px;
  background-color: #f2994a;
  color: #fff;
}
.favorites-product-description span {
  background-color: #fff;
  color: #f2994a;
  top: 15px;
  right: 15px;
}
.favorites-product-description span:hover,
.favorites-product-description a:hover {
  box-shadow: 0 5px 15px 1px #f2994a;
}

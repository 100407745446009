.card-page {
  border-radius: 40px;
  padding: 2.1rem 1rem;
  min-height: 780px;
  min-width: 100%;
  position: relative;
}
.header-of-card {
  width: 100%;
  height: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.header-of-card a {
  width: 2.3rem;
  height: 2.3rem;
  font-size: 1.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e0e0e0;
  color: #000;
  transition: all 0.3s linear;
}
.header-of-card a:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  transition: all 0.2s linear;
}
.card-single-product {
  width: 100%;
  height: 150px;
  display: flex;
  flex-direction: row;
  align-items: center;

  background-color: #f1f1ef;
  border-radius: 20px;
  position: relative;
  padding: 5px 15px 15px 15px;
  transition: all 0.3s linear;
}
.card-items-container {
  margin-top: 25px;
  border-bottom: #f2994a;
}
.card-items-container li {
  margin: 10px auto;
}
.card-product-description {
  width: 22vw;
}
.card-product-description a,
.delete-from-card-icon {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  position: absolute;
  transition: all 0.3s linear;
}

.card-product-description a {
  bottom: 15px;
  right: 15px;
  background-color: #f2994a;
  color: #fff;
}
.prices-total-box {
  width: 25vw;
  margin-top: 30px;
  padding-right: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
}
.delete-from-card-icon {
  background-color: #fff;
  color: #f2994a;
  top: 15px;
  right: 15px;
}
.prices-total-box span {
  color: #f2994a;
  margin-left: 5px;
}
.delete-from-card-icon:hover,
.card-product-description a:hover {
  box-shadow: 0 5px 15px 1px #f2994a;
}
.cards-total-price {
  margin: 15px 0;
}
.pay-with-credit {
  width: 100%;
  padding: 0 15px;
  position: absolute;
  bottom: 30px;
  left: 0;
}
.card-btn-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.card-btn {
  width: 100%;
  height: 2.8rem;
  margin: 0 auto;
  color: #fff;
  display: flex;
  font-size: 0.8rem;
  text-align: center;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: #f2994a;
  transition: all 0.2s linear;
  text-decoration: none;
}
.card-btn:hover {
  cursor: pointer;
  transition: all 0.3s linear;
  box-shadow: 0 5px 20px 2px #4af2ea88;
}

@media only screen and (max-width: 600px) {
  .card-page {
    min-height: 100vh;
    padding: 1.5rem 0.5rem;
  }
}
